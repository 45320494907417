define(['jquery', 'handlebars', 'reviewPagingTpl', 'siteObj'], function($, Handlebars, reviewPagingTpl, siteObj) {
  
  var pageNumber = 0;
  var numberOfReviews = 20;
  var xmlhttp = new XMLHttpRequest();
  var productID = siteObj.productID;
  var defaultReviewsSortOrder = siteObj.customerReviews.defaultReviewsOrder;

  function triggerPage(event, productID, pageNumber, numberOfReviews, sortByValue) {
    if (event.preventDefault) {
      event.preventDefault();
    } else {
      event.returnValue = false;
    }

    xmlhttp.onreadystatechange = function() {
      if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
        var reviewArr = JSON.parse(xmlhttp.responseText);
        parseData(reviewArr, sortByValue, numberOfReviews);
      }
    };
    var url = '/reviews.json?productId=' + productID + '&page=' + pageNumber + '&reviewsPerPage=' + numberOfReviews + '&sortBy=' + sortByValue;
    xmlhttp.open('GET', url, true);
    xmlhttp.send();
  }

  function parseData(data, sortByValue, numberOfReviews) {
    data.site = siteObj.siteCode;
    data.country = siteObj.customerLocation;

    if (data.country === 'GB' || data.country === 'ES' || data.country === 'FR' || data.country === 'IT' || data.country === 'NL' || data.country === 'PT') {
      data.countryPattern1 = true;
    } else if (data.country === 'DE') {
      data.countryPattern2 = true;
    } else if (data.country === 'FN') {
      data.countryPattern3 = true;
    } else if (data.country === 'SE') {
      data.countryPattern4 = true;
    } else {
      data.countryPattern1 = true;
    }

    data.siteSecureURL = siteObj.siteSecureURL;
    data.productID = productID;
    data.pageNumber = pageNumber;
    data.customerReviews = siteObj.customerReviews;
    data.proteinData = true;
    data.pageBlockOne = 1;
    data.pageBlockTwo = 2;
    data.pageBlockThree = 3;
    data.pageBlockFour = 4;
    data.pageBlockFive = 5;

    if (data.reviewList.page === 0) {
      data.startNumber = '1';
      if (data.reviewList.totalReviews > data.reviewList.reviewsPerPage) {
        data.toNumber = data.reviewList.reviewsPerPage;
      } else {
        data.toNumber = data.reviewList.totalReviews;
      }
    } else {
      data.startNumber = (data.reviewList.page * data.reviewList.reviewsPerPage) + 1;
      if (data.reviewList.totalReviews > data.reviewList.reviewsPerPage * (data.reviewList.page + 1)) {
        data.toNumber = (data.reviewList.page + 1) * data.reviewList.reviewsPerPage;
      } else {
        data.toNumber = data.reviewList.totalReviews;
      }
    }

    if ((data.reviewList.totalPages > 5) && (data.reviewList.page >= 4)) {
      data.pageBlockOne = data.reviewList.page - 2;
      data.pageBlockTwo = data.reviewList.page - 1;
      data.pageBlockThree = data.reviewList.page;
      data.pageBlockFour = data.reviewList.page + 1;
    } else if ((data.reviewList.totalPages > 5) && ((data.reviewList.totalPages - 1) === data.reviewList.page)) {
      data.pageBlockOne = data.reviewList.page - 3;
      data.pageBlockTwo = data.reviewList.page - 2;
      data.pageBlockThree = data.reviewList.page - 1;
      data.pageBlockFour = data.reviewList.page;
    }

    if (((data.reviewList.page + 1) <= data.reviewList.totalPages) && data.reviewList.totalPages !== 1) {
      data.showPaging = true;

      data.pageOneLink = true;
      if ((data.reviewList.page + 1) === 1) {
        data.pageOneSelected = true;
      }

      data.pageTwoLink = true;
      if ((data.reviewList.page + 1) === 2) {
        data.pageTwoSelected = true;
      }

      if (data.reviewList.totalPages >= 3) {
        data.pageThreeLink = true;
        if ((data.reviewList.page + 1) === 3) {
          data.pageThreeSelected = true;
        }

        if (data.reviewList.totalPages >= 4) {
          data.pageFourLink = true;
          if ((data.reviewList.page + 1) >= 4) {
            data.pageFourSelected = true;
          }

          if (data.reviewList.totalPages === 5) {
            data.pageFiveLink = true;
            if ((data.reviewList.page + 1) === 5) {
              data.pageFiveSelected = true;
            }
          }
        }
      }

      if (data.reviewList.totalPages > 5) {
        data.pageElipsis = true;
        data.upperPageLink = data.reviewList.totalPages;
      }
    }

    if (data.reviewList.page !== 0) {
      data.previousPage = true;
      data.previousPageNumber = data.reviewList.page - 1;
    }

    if (data.reviewList.page <= (data.reviewList.totalPages - 2)) {
      data.nextPage = true;
      data.nextPageNumber = data.reviewList.page + 1;
    }

    if (data.reviewList.reviewsPerPage === 10) {
      data.reviewTen = true;
    }
    if (data.reviewList.reviewsPerPage === 20) {
      data.reviewTwenty = true;
    }
    if (data.reviewList.reviewsPerPage === 50) {
      data.reviewFifty = true;
    }
    if (data.reviewList.reviewsPerPage === 100) {
      data.reviewOneHundred = true;
    }

    if (sortByValue === 'created') {
      data.createdSelect = true;
    } else if (sortByValue === 'rating') {
      data.ratingSelect = true;
    } else if (sortByValue === 'votes') {
      data.votesSelect = true;
    }

    for (var i = data.reviewList.reviews.length - 1; i >= 0; i--) {
      data.reviewList.reviews[i].reviewScorePercentage = (data.reviewList.reviews[i].score / 5) * 100;
      if (data.site === 'myprotein') {
        data.reviewList.reviews[i].mixabilityScorePercentage = (data.reviewList.reviews[i].mixabilityScore / 5) * 100;
        data.reviewList.reviews[i].tastePercentage = (data.reviewList.reviews[i].taste / 5) * 100;
        data.reviewList.reviews[i].effectivenessPercentage = (data.reviewList.reviews[i].effectiveness / 5) * 100;
        if (data.reviewList.reviews[i].mixabilityScorePercentage > 0 || data.reviewList.reviews[i].tastePercentage > 0 || data.reviewList.reviews[i].effectivenessPercentage > 0) {
          data.reviewList.reviews[i].addedCriteria = true;
        }
      }
      var dateSplit = data.reviewList.reviews[i].created.split('/');
      var dateMonth;
      switch (dateSplit[1]) {
        case 'Jan':
          dateMonth = '01';
          break;
        case 'Feb':
          dateMonth = '02';
          break;
        case 'Mar':
          dateMonth = '03';
          break;
        case 'Apr':
          dateMonth = '04';
          break;
        case 'May':
          dateMonth = '05';
          break;
        case 'Jun':
          dateMonth = '06';
          break;
        case 'Jul':
          dateMonth = '07';
          break;
        case 'Aug':
          dateMonth = '08';
          break;
        case 'Sep':
          dateMonth = '09';
          break;
        case 'Oct':
          dateMonth = '10';
          break;
        case 'Nov':
          dateMonth = '11';
          break;
        default:
          dateMonth = '12';
          break;
      }

      var dateConvert = dateSplit[2] + '-' + dateMonth + '-' + dateSplit[0];
      data.reviewDisclaimer = siteObj.reviewDisclaimer;
      data.reviewList.reviews[i].dateConvert = dateConvert;
      data.reviewList.reviews[i].reviewDisclaimer = siteObj.reviewDisclaimer;
      data.reviewList.reviews[i].created = data.reviewList.reviews[i].created.replace(/\//g, ' ');
      data.reviewList.reviews[i].reviewHelpful = data.customerReviews.wasHelpful;
      data.reviewList.reviews[i].totalVotes = data.customerReviews.helpfulVotes + data.customerReviews.helpfulVotes;
      // if there are some votes attached first set a variable to show the block
      if (data.reviewList.reviews[i].helpfulVotes > 0 || data.reviewList.reviews[i].unhelpfulVotes > 0) {
        data.reviewList.reviews[i].showHelpfulBlock = true;
        // if there is only one vote set variable to show this block else show the other
        if ((data.reviewList.reviews[i].helpfulVotes + data.reviewList.reviews[i].unhelpfulVotes) === 1) {
          data.reviewList.reviews[i].showHelpfulBlockSingular = true;
        } else {
          data.reviewList.reviews[i].showHelpfulBlockSingular = false;
        }
      }
    }

    var reviewMarkup = reviewPagingTpl({
      'data': data
    });

    document.getElementById('review-content-wrapper').innerHTML = reviewMarkup;

    if (data.reviewList.page > 0) {
      var previous = document.getElementById('previous');
      if (window.addEventListener) {
        previous.addEventListener('click', function(e) {
          triggerPage(e, data.productID, data.previousPageNumber, numberOfReviews, sortByValue);
          data.reviewList.page = data.reviewList.page - 1;
          scrollToId();
        });
      } else if (window.attachEvent) {
        previous.attachEvent('onclick', function(e) {
          triggerPage(e, data.productID, data.previousPageNumber, numberOfReviews, sortByValue);
          data.reviewList.page = data.reviewList.page - 1;
          scrollToId();
        });
      }
    }

    if (data.reviewList.page < (data.reviewList.totalPages - 1)) {
      var next = document.getElementById('next-button');
      if (next) {
        if (window.addEventListener) {
          next.addEventListener('click', function(e) {
            triggerPage(e, siteObj.productID, data.nextPageNumber, numberOfReviews, sortByValue);
            data.reviewList.page = data.reviewList.page + 1;
            scrollToId();
          });
        } else if (window.attachEvent) {
          next.attachEvent('onclick', function(e) {
            triggerPage(e, siteObj.productID, data.nextPageNumber, numberOfReviews, sortByValue);
            data.reviewList.page = data.reviewList.page + 1;
            scrollToId();
          });
        }
      }
    }

    var reviewsShowing = document.getElementById('reviewsShowing');
    if (reviewsShowing) {
      if (window.addEventListener) {
        reviewsShowing.addEventListener('change', function(e) {
          triggerPage(e, data.productID, data.pageNumber, reviewsShowing.value, sortByValue);
        });
      } else if (window.attachEvent) {
        reviewsShowing.attachEvent('onchange', function(e) {
          triggerPage(e, data.productID, data.pageNumber, reviewsShowing.value, sortByValue);
        });
      }
    }

    var sortBy = document.getElementById('sortingOrder');
    if (sortBy) {
      if (window.addEventListener) {
        sortBy.addEventListener('change', function(e) {
          triggerPage(e, data.productID, data.pageNumber, numberOfReviews, sortBy.value);
        });
      } else if (window.attachEvent) {
        sortBy.attachEvent('onchange', function(e) {
          triggerPage(e, data.productID, data.pageNumber, numberOfReviews, sortBy.value);
        });
      }
    }

    // page numbers

    if (data.showPaging === true) {

      // handle six page number blocks


      var pageOne = document.getElementById('pageOne');
      if (pageOne) {
        if (window.addEventListener) {
          pageOne.addEventListener('click', function(e) {
            triggerPage(e, data.productID, (data.pageBlockOne - 1), numberOfReviews, sortByValue);
            data.reviewList.page = data.pageBlockOne - 1;
            scrollToId();
          });
        } else if (window.attachEvent) {
          pageOne.attachEvent('onclick', function(e) {
            triggerPage(e, data.productID, (data.pageBlockOne - 1), numberOfReviews, sortByValue);
            data.reviewList.page = data.pageBlockOne - 1;
            scrollToId();
          });
        }
      }

      var pageTwo = document.getElementById('pageTwo');
      if (pageTwo) {
        if (window.addEventListener) {
          pageTwo.addEventListener('click', function(e) {
            triggerPage(e, data.productID, (data.pageBlockTwo - 1), numberOfReviews, sortByValue);
            data.reviewList.page = data.pageBlockTwo - 1;
            scrollToId();
          });
        } else if (window.attachEvent) {
          pageTwo.attachEvent('onclick', function(e) {
            triggerPage(e, data.productID, (data.pageBlockTwo - 1), numberOfReviews, sortByValue);
            data.reviewList.page = data.pageBlockTwo - 1;
            scrollToId();
          });
        }
      }

      if (data.pageThreeLink) {
        var pageThree = document.getElementById('pageThree');
        if (pageThree) {
          if (window.addEventListener) {
            pageThree.addEventListener('click', function(e) {
              triggerPage(e, data.productID, (data.pageBlockThree - 1), numberOfReviews, sortByValue);
              data.reviewList.page = data.pageBlockThree - 1;
              scrollToId();
            });
          } else if (window.attachEvent) {
            pageThree.attachEvent('onclick', function(e) {
              triggerPage(e, data.productID, (data.pageBlockThree - 1), numberOfReviews, sortByValue);
              data.reviewList.page = data.pageBlockThree - 1;
              scrollToId();
            });
          }
        }
      }

      if (data.pageFourLink) {
        var pageFour = document.getElementById('pageFour');
        if (pageFour) {
          if (window.addEventListener) {
            pageFour.addEventListener('click', function(e) {
              triggerPage(e, data.productID, (data.pageBlockFour - 1), numberOfReviews, sortByValue);
              data.reviewList.page = data.pageBlockFour - 1;
              scrollToId();
            });
          } else if (window.attachEvent) {
            pageFour.attachEvent('onclick', function(e) {
              triggerPage(e, data.productID, (data.pageBlockFour - 1), numberOfReviews, sortByValue);
              data.reviewList.page = data.pageBlockFour - 1;
              scrollToId();
            });
          }
        }
      }

      if (data.pageFiveLink) {
        var pageFive = document.getElementById('pageFive');
        if (pageFive) {
          if (window.addEventListener) {
            pageFive.addEventListener('click', function(e) {
              triggerPage(e, data.productID, (data.pageBlockFive - 1), numberOfReviews, sortByValue);
              data.reviewList.page = data.pageBlockFive - 1;
              scrollToId();
            });
          } else if (window.attachEvent) {
            pageFive.attachEvent('onclick', function(e) {
              triggerPage(e, data.productID, (data.pageBlockFive - 1), numberOfReviews, sortByValue);
              data.reviewList.page = data.pageBlockFive - 1;
              scrollToId();
            });
          }
        }
      }

      if (data.pageElipsis) {
        var pageUpper = document.getElementById('pageUpper');
        if (pageUpper) {
          if (window.addEventListener) {
            pageUpper.addEventListener('click', function(e) {
              triggerPage(e, data.productID, (data.upperPageLink - 1), numberOfReviews, sortByValue);
              scrollToId();
            });
          } else if (window.attachEvent) {
            pageUpper.attachEvent('onclick', function(e) {
              triggerPage(e, data.productID, (data.upperPageLink - 1), numberOfReviews, sortByValue);
              scrollToId();
            });
          }
        }
      }
    }

  }

  function loadControls() {

    var next = document.getElementById('next-button');
    if (next) {
      if (window.addEventListener) {
        next.addEventListener('click', function(e) {
          triggerPage(e, siteObj.productID, 1, numberOfReviews, defaultReviewsSortOrder);
          scrollToId();
        });
      } else if (window.attachEvent) {
        next.attachEvent('onclick', function(e) {
          triggerPage(e, siteObj.productID, 1, numberOfReviews, defaultReviewsSortOrder);
          scrollToId();
        });
      }
    }

    var reviewsShowingLoad = document.getElementById('reviewsShowing');
    if (reviewsShowingLoad) {
      if (window.addEventListener) {
        reviewsShowingLoad.addEventListener('change', function(e) {
          triggerPage(e, siteObj.productID, 0, reviewsShowingLoad.value, defaultReviewsSortOrder);
        });
      } else if (window.attachEvent) {
        reviewsShowingLoad.attachEvent('onchange', function(e) {
          triggerPage(e, siteObj.productID, 0, reviewsShowingLoad.value, defaultReviewsSortOrder);
        });
      }
    }

    var sortBy = document.getElementById('sortingOrder');
    if (sortBy) {
      if (window.addEventListener) {
        sortBy.addEventListener('change', function(e) {
          triggerPage(e, siteObj.productID, 0, numberOfReviews, sortBy.value);
        });
      } else if (window.attachEvent) {
        sortBy.attachEvent('onchange', function(e) {
          triggerPage(e, siteObj.productID, 0, numberOfReviews, sortBy.value);
        });
      }
    }

    var pageOne = document.getElementById('pageOne');
    if (pageOne) {
      if (window.addEventListener) {
        pageOne.addEventListener('click', function(e) {
          triggerPage(e, siteObj.productID, 0, numberOfReviews, defaultReviewsSortOrder);
          scrollToId();
        });
      } else if (window.attachEvent) {
        pageOne.attachEvent('onclick', function(e) {
          triggerPage(e, siteObj.productID, 0, numberOfReviews, defaultReviewsSortOrder);
          scrollToId();
        });
      }
    }

    var pageTwo = document.getElementById('pageTwo');
    if (pageTwo) {
      if (window.addEventListener) {
        pageTwo.addEventListener('click', function(e) {
          triggerPage(e, siteObj.productID, 1, numberOfReviews, defaultReviewsSortOrder);
          scrollToId();
        });
      } else if (window.attachEvent) {
        pageTwo.attachEvent('onclick', function(e) {
          triggerPage(e, siteObj.productID, 1, numberOfReviews, defaultReviewsSortOrder);
          scrollToId();
        });
      }
    }

    var pageThree = document.getElementById('pageThree');
    if (pageThree) {
      if (window.addEventListener) {
        pageThree.addEventListener('click', function(e) {
          triggerPage(e, siteObj.productID, 2, numberOfReviews, defaultReviewsSortOrder);
          scrollToId();
        });
      } else if (window.attachEvent) {
        pageThree.attachEvent('onclick', function(e) {
          triggerPage(e, siteObj.productID, 2, numberOfReviews, defaultReviewsSortOrder);
          scrollToId();
        });
      }
    }

    var pageFour = document.getElementById('pageFour');
    if (pageFour) {
      if (window.addEventListener) {
        pageFour.addEventListener('click', function(e) {
          triggerPage(e, siteObj.productID, 3, numberOfReviews, defaultReviewsSortOrder);
          scrollToId();
        });
      } else if (window.attachEvent) {
        pageFour.attachEvent('onclick', function(e) {
          triggerPage(e, siteObj.productID, 3, numberOfReviews, defaultReviewsSortOrder);
          scrollToId();
        });
      }
    }

    var pageFive = document.getElementById('pageFive');
    if (pageFive) {
      if (window.addEventListener) {
        pageFive.addEventListener('click', function(e) {
          triggerPage(e, siteObj.productID, 4, numberOfReviews, defaultReviewsSortOrder);
          scrollToId();
        });
      } else if (window.attachEvent) {
        pageFive.attachEvent('onclick', function(e) {
          triggerPage(e, siteObj.productID, 4, numberOfReviews, defaultReviewsSortOrder);
          scrollToId();
        });
      }
    }

    var pageUpper = document.getElementById('pageUpper');
    if (pageUpper) {
      if (window.addEventListener) {
        pageUpper.addEventListener('click', function(e) {
          triggerPage(e, siteObj.productID, 1, numberOfReviews, defaultReviewsSortOrder);
          scrollToId();
        });
      } else if (window.attachEvent) {
        pageUpper.attachEvent('onclick', function(e) {
          triggerPage(e, siteObj.productID, 1, numberOfReviews, defaultReviewsSortOrder);
          scrollToId();
        });
      }
    }

  }

  function scrollToId() {
    $('html,body').animate({
      scrollTop: $('#customer-reviews').offset().top
    });
  }

  loadControls();

});
